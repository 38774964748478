.logo{
    cursor: pointer;
}
.align-center{
    align-items: center;
}
.LoadingOver{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.30);
    z-index: 1056;
}
.SpinnerPage{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.SpinnerBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}
.SpinnerPageDetail{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.spinner-border{
    width: 8rem;
    height: 8rem;
}
.CircularProgressbar{
    width: 200px!important;
}
/*** SIDEBAR *****/

#sidebar{
    background-color: #f6f6f6;
    border: 1px solid #dadada;
}
#sidebar a{
 font-size: 16px;
 margin-bottom: 10px;
 color: #252454;
}
#sidebar a:last-child{
 margin-bottom: 0
}
#sidebar a.active{
  color: #57a08f;
}
#sidebar h3 {
    padding: 15px 10px;
    color: #fff;
    background-color: #1E2867;
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;
}
#sidebar .greenColor{
  background-color: #57a08f;
}
#sidebar h3 i{
    color: white;
    font-size: 18px;
    float: left;
    margin: 0 8px 0 0;
}
#sidebar h3 .ButtonToggle i{
    margin: 0;
}
#sidebar ul{
    margin: 0;
    padding: 0;
}
#sidebar div:last-child ul{
    margin-bottom: 0;
    padding-left: 0;
}
#sidebar ul li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative;
}
#sidebar ul li a{
    display: block;
    font-size: 13px;
    font-weight: 400;
    padding: 12px 12px;
    text-decoration: none;
    position: relative;
    border-bottom: 1px solid #ededed;
}
#sidebar ul li:last-child a{
 border-bottom: none
}
#sidebar ul li a:hover{
 background-color: #00000020;
}
#sidebar .div-overflow{
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ButtonToggle{
  cursor: pointer;
  float: right;
  background: none;
  border: none;
    margin-left: auto;
}
.ButtonToggle2{
  cursor: pointer;
  float: right;
  background: none;
  border: none;
}
.div-overflow::-webkit-scrollbar {
  width: 4px;
}

.div-overflow::-webkit-scrollbar-track {
  border-radius: 10px;
}

.div-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.css-xilmwd-MuiPaper-root-MuiAlert-root{
    align-items: center;
}
.verifier{
    padding: 6px;
    font-size: 16px;
    font-weight: bold;
    background-color: #ffcfcf;
    border: 1px solid #ffcfcf;
}
.ButtonAddTicker{
    background-color: #1E2867;
}

.rtf {
    bottom: 50px !important;
    right: 24px !important;
}

.rtf .rtf--mb__c {
    margin: 0 !important;
    padding: 0 !important;
}
