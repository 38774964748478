.documentName {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blocTop {
    background-color: #e5e5f2;
    padding: 7px 10px;
    margin-bottom: 30px;
}

.backgroudWhite {
    background-color: white;
}

.backgroudGrey {
    background-color: #e9ecef;
}

.backgroudGrey input, .backgroudGrey label {
    -webkit-text-fill-color: rgba(0, 0, 0) !important;
}

.backgroudGrey input[type=file] {
    -webkit-text-fill-color: rgba(0, 0, 0);
}

.datepicker label {
    transform: translate(14px, 10px) scale(1);
}

.datepicker label[data-shrink=true] {
    transform: translate(14px, -9px) scale(0.75);
}

.datepicker input {
    padding: 8.5px 14px;
}

.GlobalDate {
    display: flex;
    flex-wrap: wrap;
}

.Dates {
    border: 1px solid;
    border-radius: 10%;
    padding: 2px;
    margin: 5px;
    display: grid;
    grid-template-columns: 1fr 15px;
    font-size: 15px;
    background-color: whitesmoke;
}

.ButtonDates {
    padding: 0px;
    outline: none;
    background: none;
    border: none;
}

.ButtonDates:hover {
    outline: none;
    background: none;
    border: none;
}

.ButtonDates:active {
    outline: none;
    background: none;
    border: none;
}

.fileupload {
    height: 40px !important;
    width: 45px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    font-size: 20px;
    background-color: white;
}

.fileupload2 {
    height: 30px !important;
    width: 45px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    font-size: 20px;
}

.inputfileupload {
    position: absolute;
    right: 0px;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
}

.fileupload:hover {
    border: none;
    background: none;
    outline: none;
}

.fileupload:active {
    border: none;
    background: none;
    outline: none;
}

.fileupload3 {
    height: 30px !important;
    width: 45px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    font-size: 20px;
}

.fileupload2 {
    height: 30px !important;
    width: 45px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    font-size: 20px;
}

.inputfileupload2 {
    position: absolute;
    right: 0px;
    top: 0;
    opacity: 0;
    height: 30px;
    width: 100%;
}

.fileupload:hover {
    border: none;
    background: none;
    outline: none;
}

.fileupload:active {
    border: none;
    background: none;
    outline: none;
}

.Border-content {
    border: 1px solid;
    padding: 15px 10px 5px;
    position: relative;
    margin-bottom: 15px;
    margin-top: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;
    background-color: #e5e5f2;
}

.Border-content .button-delete {
    border: none;
    background: none;
    outline: none;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 25px;
    line-height: 25px;
    color: red;
}

.button-add {
    margin: 5px 10px 0px;
    border: none;
    background: none;
    outline: none;
}

.button-add span {
    color: rgb(37 36 84);
    font-size: 20px;
    font-weight: bold;
}
