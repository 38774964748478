body {
    height: 100%;
    overflow-x: hidden;
    font-family: "Nunito", "Helvetica Neue", helvetica, arial, sans-serif;
}

.paginationBox {
    display: flex;
    justify-content: flex-end;
}

#footer {
    margin-top: 10px;
    background-color: #1E2867;
    padding: 40px 0;
}

a {
    color: #3326e2;
}

.error {
    color: red;
}

.BlockError {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.BlockError svg {
    font-size: 50px;
    color: red;
    margin-bottom: 10px;
}

.BlockError p {
    font-size: 15px;
}

.Edit-btn {
    background-color: #252454;
    border-color: #252454;
    color: white;
    padding: 5px;
    transition: all 200ms ease;
    cursor: pointer;
}

.Edit-btn:hover {
    background: none;
    border: none;
    color: #252454;
}

.graphBloc {
    background-color: #f6f6f6;
    border: 1px solid #dadada;
    border-radius: 3px;
    padding: 20px;
    font-size: 13px;
    margin-bottom: 30px;
}

.graphBlocError {
    background-color: #fff;
    border: 1px solid #ff0000;
    border-radius: 3px;
    padding: 20px;
    font-size: 13px;
    margin-bottom: 30px;
}

.graphBloc a {
    text-decoration: none;
}

.overlayLoad {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 10px;
    background: #00000080;
}

.EditFacture {
    cursor: pointer;
    color: #252454;
    text-decoration: underline;
}

.calendar .months-container .month-container.month-2 {
    width: 25% !important;
}

.calendar {
    overflow: hidden;
}

.NonstafeeDiv {
    display: flex;
    align-items: center;
}

.NonstafeeDiv p {
    font-size: 17px;
    margin-bottom: 0;
    margin-right: 5px;
}

.NonstafeeDiv .btn span {
    line-height: 30px;
}

.blocTop {
    background-color: #e5e5f2;
    padding: 7px 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
}

.menuMobile {
    display: none
}

.hideMenu {
    display: block
}

@media screen and (max-width: 900px) {
    .DisplayNoneMobile {
        display: none;
    }

    .menuMobile {
        display: inline-block
    }

    .hideMenu {
        display: none
    }
}

.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error-img {
    width: 40%;
}

.error-button{
    background-color: #1e2867;
    border: none;
    padding: 12px 23px;
    color: #fff;
    border-radius: 10px;
}


iframe {
            height: 1200px;
            width: 1200px;
        }